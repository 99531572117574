<template>
    <div ref="lottieContainer" :style="style"></div>
</template>

<script>
import lottie from 'lottie-web'
export default {
    name: 'LottiePlayer',
    props: {
        options: {
            type: Object,
            required: true,
        },
        height: {
            type: [Number, String],
            required: false,
            default: () => '100%',
        },
        width: {
            type: [Number, String],
            required: false,
            default: () => '100%',
        },
    },
    emits: ['animationCreated'],
    data() {
        return {
            style: {
                width: this.width ? `${this.width}px` : '100%',
                height: this.height ? `${this.height}px` : '100%',
                overflow: 'hidden',
            },
        }
    },
    mounted() {
        this.anim = lottie.loadAnimation({
            container: this.$refs.lottieContainer,
            renderer: 'svg',
            loop: this.options.loop !== false,
            autoplay: this.options.autoplay !== false,
            animationData: this.options.animationData,
            rendererSettings: this.options.rendererSettings,
        })
        this.$emit('animationCreated', this.anim)
    },
}
</script>
