import { render, staticRenderFns } from "./CupboardsGameGenerator.vue?vue&type=template&id=512b2cdc&scoped=true"
import script from "./CupboardsGameGenerator.vue?vue&type=script&lang=js"
export * from "./CupboardsGameGenerator.vue?vue&type=script&lang=js"
import style0 from "./CupboardsGameGenerator.vue?vue&type=style&index=0&id=512b2cdc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "512b2cdc",
  null
  
)

export default component.exports