<template>
    <div class="footer">
        <div class="content">
            <slot></slot>
        </div>
        <div class="actions">
            <slot name="actions" />
            <div class="button close-button clickable" @click="close">
                <font-awesome-icon :icon="['far', 'times']" />
            </div>
        </div>
    </div>
</template>

<script>
import { GAMES } from '../../../store/stations/3.001_cupboards/cupboards'
export default {
    name: 'CupboardsGameFooter',
    components: {},
    props: {
        customClose: {
            type: Boolean,
            default: () => false,
        },
    },
    emits: ['close'],
    data() {
        return {}
    },
    computed: {},
    methods: {
        close() {
            if (this.customClose) {
                this.$emit('close')
            } else {
                this.$store.dispatch('cupboards/setGame', GAMES.NONE)
            }
        },
    },
}
</script>

<style scoped lang="scss">
.footer {
    width: 100%;
    height: 15%;
    display: flex;
    flex-direction: row;
    padding-left: 50px;
    padding-right: 50px;
    .content {
        flex-grow: 1;
        align-items: center;
        display: flex;
    }
    .actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        //> * {
        //    margin-left: 32px;
        //}
        .button {
            height: 44px;
            padding: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 24px;
        }
        .close-button {
            width: 44px;

            svg {
                width: 30px;
                height: 30px;
            }
        }
    }
}
</style>
