<template>
    <div
        v-if="
            $store.getters['cupboardsGameGenerator/hasItemCategory'](category)
        "
        class="filter"
    >
        <div class="label">
            <div class="label-value">
                {{ $t(label) }}
            </div>
            <div class="label-suffix">:</div>
        </div>
        <div class="value">
            <ul class="options">
                <template
                    v-for="option in $store.state.cupboardsGameGenerator.item
                        .goettItem[category]"
                >
                    <li
                        v-if="
                            $store.getters[
                                'cupboardsGameGenerator/hasCategoryAndPropEnoughRelatedItems'
                            ](category, option)
                        "
                        :key="option"
                        class="option"
                    >
                        <div
                            class="value-label"
                            @click="setCategoryAndProp(category, option)"
                        >
                            {{ option }}
                        </div>
                        <radio
                            :selected="
                                $store.getters[
                                    'cupboardsGameGenerator/isCategoryAndPropSelected'
                                ](category, option)
                            "
                            :group="`${category}_${option}`"
                            @valueChanged="onRadioValueChanged($event, option)"
                            @click="setCategoryAndProp(category, option)"
                        ></radio>
                    </li>
                </template>
            </ul>
        </div>
    </div>
</template>
<script>
import { FIELDS } from '../../../../mixins/api'
import Radio from './Radio.vue'

export default {
    name: 'CupboardsGameGeneratorFilters',
    components: { Radio },
    props: {
        category: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            FIELDS,
            value: null,
            notifyNextChange: true,
        }
    },
    methods: {
        setCategoryAndProp(category, prop) {
            this.$store.dispatch(
                'cupboardsGameGenerator/setSelectedCategoryAndProp',
                {
                    category,
                    prop,
                },
            )
        },
        onRadioValueChanged(event, prop) {
            this.$store.dispatch(
                'cupboardsGameGenerator/setSelectedCategoryAndProp',
                { category: this.category, prop },
            )
        },
    },
}
</script>
<style lang="scss" scoped>
.filter {
    display: flex;
    margin-bottom: 12px;
    .label {
        font-weight: bold;
        width: 33%;
        display: flex;
        .label-value {
            flex-grow: 1;
        }
    }
    .value {
        width: 33%;
        .option {
            display: flex;
            .value-label {
                flex-grow: 1;
            }
        }
    }
}
</style>
