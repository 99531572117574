<template>
    <BoxWrapper>
        <template #button>
            <button
                v-if="hasCloseButton"
                class="button clickable close-info mb-1 border-0"
                @click="closeInfo()"
            >
                <font-awesome-icon :icon="['far', 'times']" size="2x" />
            </button>
        </template>
        <template #content>
            <img
                v-if="thumbnail"
                :src="thumbnail"
                width="200px"
                height="auto"
            />
            <img
                v-else-if="!hideThumbnail"
                :src="
                    $store.getters['items/getBlob']({
                        item: item,
                        width: 200,
                    })
                "
                width="200px"
                height="auto"
            />
            <div class="details">
                <Scroller :navi="true" :step="50">
                    <ul>
                        <template v-for="field in goettFields">
                            <li v-if="item.goettItem[field]" :key="field">
                                <div class="label">{{ $t(field) }}:</div>
                                <div
                                    class="value"
                                    v-html="getValue(item, field)"
                                ></div>
                            </li>
                        </template>
                        <template v-for="field in customFields">
                            <li
                                v-if="item[field.objectKey][field.propertyKey]"
                                :key="`${field.objectKey}_${field.propertyKey}`"
                            >
                                <div v-if="field.label" class="label">
                                    {{ $t(field.label) }}:
                                </div>
                                <div v-else class="label">
                                    {{ $t(field.propertyKey) }}:
                                </div>
                                <div
                                    class="value"
                                    v-html="
                                        item[field.objectKey][field.propertyKey]
                                    "
                                ></div>
                            </li>
                        </template>
                    </ul>
                </Scroller>
            </div>
        </template>
    </BoxWrapper>
</template>

<script>
import { FIELDS } from '../../mixins/api'
import Scroller from './Scroller'
import BoxWrapper from '@/components/global/BoxWrapper'

export default {
    name: 'ItemInfo',
    components: { BoxWrapper, Scroller },
    props: {
        item: {
            type: Object,
            required: true,
        },
        goettFields: {
            type: Array,
            default: () => [
                FIELDS.LABEL,
                // FIELDS.MD_UNIGOE_BESCHREIBUNG,
                FIELDS.MD_UNIGOE_OBJEKTGATTUNG,
                FIELDS.MD_UNIGOE_MATERIAL_ANZEIGE,
            ],
        },
        customFields: {
            type: Array,
            required: false,
            default: () => [],
        },
        hasCloseButton: {
            type: Boolean,
            required: false,
            default: () => false,
        },
        hideThumbnail: {
            type: Boolean,
            required: false,
            default: () => false,
        },
        thumbnail: {
            type: [Object, String],
            required: false,
            default: () => null,
        },
    },
    data() {
        return { FIELDS }
    },
    async mounted() {},
    methods: {
        getValue(item, field) {
            return typeof item.goettItem[field] === 'string'
                ? item.goettItem[field]
                : item.goettItem[field][0]
        },
        closeInfo() {
            this.$emit('info-closed')
        },
    },
}
</script>

<style lang="scss" scoped>
.content {
    padding: 20px 0px;
    .details {
        overflow-y: auto;
        max-height: 500px;

        ul {
            list-style-type: none;
            columns: 2;
            padding: 20px;

            li {
                margin-bottom: 8px;
                break-inside: avoid-column;

                .label {
                    font-weight: bold;
                }
            }
        }
    }

    img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        padding: 20px;
        max-width: 30%;
    }
}

.close-info {
    z-index: 1000;
    pointer-events: auto;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        width: 24px;
        height: 24px;
    }
}
</style>
