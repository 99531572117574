<template>
    <div class="flickity-container">
        <button
            v-if="slidesNumber > 1"
            class="prev navi-btn"
            :disabled="currentSlide === 1"
            :class="{ disabled: currentSlide === 1 }"
            @click="prevSlide()"
        >
            <font-awesome-icon :icon="['far', 'angle-left']" size="sm" />
        </button>
        <button
            v-if="slidesNumber > 1"
            class="next navi-btn"
            :disabled="currentSlide === slidesNumber"
            :class="{ disabled: currentSlide === slidesNumber }"
            @click="nextSlide()"
        >
            <font-awesome-icon :icon="['far', 'angle-right']" size="sm" />
        </button>

        <div class="flickity-container-inner">
            <div ref="flickity" class="flickity">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import Flickity from 'flickity'

export default {
    name: 'FilterSlider',
    props: {
        slidesToShow: {
            type: Number,
            default: 3,
        },
    },
    data() {
        return {
            flickity: null,
            flickityOptions: {
                prevNextButtons: false,
                pageDots: false,
                draggable: false,
                cellAlign: 'left',
                groupCells: 3,
            },
            slidesNumber: 0,
            currentSlide: 0,
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.flickity = new Flickity(
                this.$refs.flickity,
                this.flickityOptions,
            )
            this.updateFlickity()
        })
    },
    methods: {
        updateFlickity() {
            this.currentSlide = this.flickity.selectedIndex + 1
            this.slidesNumber = this.flickity.slides.length

            // console.log(this.currentSlide)
            // console.log(this.slidesNumber)
        },
        prevSlide() {
            this.flickity.previous()
            this.updateFlickity()
        },
        nextSlide() {
            this.flickity.next()
            this.updateFlickity()
        },
    },
}
</script>

<style lang="scss" scoped>
.flickity,
.flickity-slider,
.flickity-viewport,
.carousel-cell {
    height: 100% !important;
    /*overflow-y: visible;*/
    z-index: 99;

    overflow: hidden;
}

.flickity-container {
    width: 100%;
    padding: 0 60px;
    z-index: 999;
    /*overflow-y: auto;*/
}

.flickity-viewport {
    margin: 0 50px;
    width: 100%;
}

.flickity-slider {
    //padding-left: 50px;
}

.flickity .prev,
.flickity .next {
    position: absolute;
    top: 0px;

    padding-right: 0px;
}

.flickity .prev {
    left: 0px;
}

.flickity .next {
    right: 0px;
}

.flickity-container,
.flickity-container-inner,
.flickity-slider {
    width: 100%;
    height: 100%;
    position: relative;
}

.flickity {
    padding: 0 60px;
}

.flickity div.filter {
    width: 33.34%;
}

.flickity-prev-next-button {
    position: absolute;
    background-color: #1d64b0;
    z-index: 100;
}

/*!* arrow color *!*/
/*.flickity-prev-next-button .arrow {*/
/*    fill: white;*/
/*}*/
/*.flickity-prev-next-button.no-svg {*/
/*    color: white;*/
/*}*/

button.navi-btn {
    appearance: none;
    background-color: white;
    position: absolute;
    z-index: 999;
    top: 50%;
    transform: translateY(-50%);
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: calc(3vw * var(--pxToVw)) solid var(--semilightgrey);
    &.disabled {
        opacity: 0.5;
    }
}

.prev {
    left: 10px;
}

.next {
    right: 10px;
}
</style>
