<template>
    <div class="visualisation">
        <div ref="truck" class="truck">
            <img src="./models/truck/1x/mit-outline.png" />
            <img
                ref="box"
                src="./models/truck/1x/box.png"
                :style="getBoxStyle()"
            />
            <img src="./models/truck/1x/kasten.png" />
        </div>

        <div class="description">
            {{
                $t('cupboards_generator_visualisation_explaination', {
                    count,
                })
            }}
        </div>
    </div>
</template>
<script>
import api from '../../../../mixins/api'
export default {
    name: 'CupboardsGameGeneratorVisualisation',
    data() {
        return { api, isMounted: false, count: 0 }
    },
    computed: {},
    watch: {
        '$store.state.cupboardsGameGenerator.selectedProps': function(value) {
            this.startIndex = 0
            const selectedProps = JSON.parse(JSON.stringify(value))
            const relatedItems = JSON.parse(
                JSON.stringify(
                    this.$store.state.cupboardsGameGenerator.relatedItems,
                ),
            )
            const selectedCategory = JSON.parse(
                JSON.stringify(
                    this.$store.state.cupboardsGameGenerator.selectedCategory,
                ),
            )
            const prop = selectedProps[selectedCategory]
            const items = [...relatedItems[selectedCategory][prop]]
            this.count = items.length
        },
    },
    mounted() {
        this.isMounted = true // fire rerender, so refs are available in computed prop at first render, TODO: why is it remounting
    },

    methods: {
        getBoxStyle() {
            const size =
                this.$store.getters[
                    'cupboardsGameGenerator/getFillPercentage'
                ] / 100
            return {
                transform: `scale(${size}) translateX(0%) translateY(0%)`,
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.visualisation {
    .truck {
        width: 380px;
        height: 380px;
        margin: auto;
        img {
            position: absolute;
            width: 400px;
        }
    }
    .description {
        font-size: 28px;
    }
}
</style>
