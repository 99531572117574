<template>
    <div class="texts">
        <div class="instructions">
            {{ $t('cupboards_generator_instruction') }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'CupboardsGeneratorTexts',
    data() {
        return {}
    },
    computed: {},
    async mounted() {},
    beforeDestroy: function() {},
    methods: {},
}
</script>

<style scoped lang="scss">
.texts {
    padding-left: 50px;
    font-size: 32px;
    width: 800px;
    color: var(--secondary);
}
</style>
