<template>
    <div class="haystack h-100 w-100">
        <cupboards-game-header
            class="d-flex w-100 justify-content-between"
            :game-title="$t('cupboards_haystack_title')"
        >
            <div
                v-if="areLivesShown()"
                class="livesText d-flex align-items-center "
            >
                {{
                    $t('haystack_lives_hint', {
                        count:
                            $store.getters[
                                'cupboardsGameHaystack/getRemainingTries'
                            ],
                    })
                }}
            </div>

            <div v-if="areLivesShown()" class="lives d-flex align-items-center">
                <font-awesome-icon
                    v-for="i in 3 -
                        $store.getters[
                            'cupboardsGameHaystack/getRemainingTries'
                        ]"
                    :key="`life-lost-${i}`"
                    size="2x"
                    class="life"
                    :icon="['fas', 'frown']"
                />
                <font-awesome-icon
                    v-for="i in $store.getters[
                        'cupboardsGameHaystack/getRemainingTries'
                    ]"
                    :key="`life-remaining-${i}`"
                    size="2x"
                    class="life"
                    :icon="['far', 'smile']"
                />
            </div>
        </cupboards-game-header>
        <div class="content">
            <texts class="texts"></texts>

            <div
                v-if="
                    $store.getters['cupboardsGameHaystack/isStep'](
                        STEPS.INTRODUCTION,
                    ) ||
                        ($store.getters['cupboardsGameHaystack/isStep'](
                            STEPS.GAMEOVER,
                        ) &&
                            $store.state.cupboardsGameHaystack.selectResult ===
                                SELECTRESULTS.SUCCESS)
                "
                class="introduction"
            >
                <item-info
                    class="itemInfo"
                    :thumbnail="
                        api.goettingen.getIsolatedThumbnailUrl(
                            $store.state.cupboardsGameHaystack.item.goettItem,
                            512,
                        )
                    "
                    :has-close-button="false"
                    :item="$store.state.cupboardsGameHaystack.item"
                    :goett-fields="[
                        FIELDS.MD_UNIGOE_OBJEKTGATTUNG,
                        FIELDS.MD_UNIGOE_MATERIAL_ANZEIGE,
                        FIELDS.MD_UNIGOE_PERSONEN,
                    ]"
                    :custom-fields="customFields"
                    @info-closed="infoClosed()"
                />
            </div>
            <div class="interactive-area">
                <aside>
                    <div
                        v-if="showConfetti"
                        class="single-layer mt-5 success confetti"
                    >
                        <img :src="getLayer()" alt="" />
                    </div>
                    <filters v-else class="filters"></filters>
                </aside>
                <div ref="matter" class="items">
                    <div>
                        <img
                            v-for="item in $store.state.cupboardsGameHaystack
                                .items"
                            :key="`item-${item.goettItem.PI}`"
                            v-long-press="300"
                            :src="
                                $store.getters['items/getIsolatedBlob']({
                                    item,
                                    width: 100,
                                })
                            "
                            :style="getItemStyle(item)"
                            :class="{
                                disabled: !$store.getters[
                                    'cupboardsGameHaystack/isItemInFilteredItems'
                                ](item),
                            }"
                            @click="itemSelected(item)"
                            @long-press-start="onLongPressStart(item, $event)"
                            @long-press-stop="onLongPressStop(item, $event)"
                        />
                    </div>
                </div>
            </div>
        </div>
        <cupboards-game-footer class="footer">
            <template slot="actions">
                <div
                    v-if="
                        $store.getters['cupboardsGameHaystack/isStep'](
                            STEPS.INTRODUCTION,
                        )
                    "
                    class="button startButton clickable"
                    @click="infoClosed"
                >
                    starten
                </div>
            </template>
        </cupboards-game-footer>
        <div
            v-if="$store.getters['cupboardsGameHaystack/isStep'](STEPS.RESULT)"
            class="result"
        ></div>
    </div>
</template>

<script>
import LongPress from 'vue-directive-long-press'
import api, { FIELDS } from '@/mixins/api'
import {
    SELECTRESULTS,
    STEPS,
} from '@/store/stations/3.001_cupboards/gameHaystack'
import { getWeightInG, getDiameterInMm } from '@/utils/items'

import Filters from './Filters.vue'
import ItemInfo from '@/components/global/ItemInfo.vue'
import CupboardsGameHeader from '../CupboardsGameHeader.vue'
import CupboardsGameFooter from '../CupboardsGameFooter.vue'
import Texts from './Texts.vue'
import LottiePlayer from '@/components/global/LottiePlayer.vue'
import animationData from './confetti.json'

import UnloadAnimation from '@/utils/UnloadAnimation'
import { GAMES } from '@/store/stations/3.001_cupboards/cupboards'

export default {
    name: 'CupboardsGameHaystack',
    components: {
        Filters,
        ItemInfo,
        CupboardsGameHeader,
        CupboardsGameFooter,
        Texts,
        LottiePlayer,
    },
    directives: {
        'long-press': LongPress,
    },
    data() {
        return {
            api,
            FIELDS,
            STEPS,
            SELECTRESULTS,
            GAMES,
            defaultOptions: { animationData },
            positions: {},
            getWeightInG,
            getDiameterInMm,
            preloadItems: false,
            showConfetti: false,
            customFields: [
                {
                    objectKey: 'kenomItem',
                    propertyKey: 'authenticity',
                },
                {
                    objectKey: 'kenomItem',
                    propertyKey: 'diameter',
                },
                {
                    objectKey: 'kenomItem',
                    propertyKey: 'weight',
                },
                {
                    objectKey: 'kenomItem',
                    propertyKey: 'earliestDate',
                    label: 'date',
                },
            ],
        }
    },

    watch: {
        '$store.state.cupboardsGameHaystack.step': {
            async handler(value, oldValue) {
                if (value === STEPS.INTRODUCTION) {
                    this.$store.dispatch('cupboardsGameHaystack/resetFilters')
                    this.unloadAnimation.start()
                }
                if (
                    value === STEPS.GAMEOVER &&
                    this.$store.state.cupboardsGameHaystack.selectResult !==
                        this.SELECTRESULTS.MISTAKE
                ) {
                    this.showConfetti = true
                }
            },
        },
    },
    mounted() {
        this.unloadAnimation = new UnloadAnimation(
            this.$refs.matter,
            positions => {
                // const rect = this.$refs.matter.getBoundingClientRect()
                const newPositions = {}
                Object.entries(positions).forEach(entry => {
                    newPositions[entry[0]] = entry[1]
                })
                this.positions = newPositions
            },
            { center: { x: 1 / 2, y: 1 / 2 } },
        )
        this.$store.state.cupboardsGameHaystack.items.forEach(item => {
            this.unloadAnimation.addBody(
                item.goettItem.PI,
                null,
                { x: 0, y: 0 },
                24,
            )
        })
        setTimeout(() => {
            this.preloadItems = true
        }, 100)
    },
    beforeDestroy() {
        this.showConfetti = false
    },
    methods: {
        itemSelected(item) {
            this.$store.dispatch('cupboardsGameHaystack/selectItem', item)
        },
        onLongPressStart(item) {
            this.$store.dispatch('cupboardsGameHaystack/setFeaturedItem', item)
        },
        onLongPressStop(item) {
            // triggers on mouseup of document
        },
        uniqueFilter(value, index, self) {
            return self.indexOf(value) === index
        },
        getItemStyle(item) {
            const debug = Object.keys(this.$route.query).includes('debug')
            if (!Object.keys(this.positions).includes(item.goettItem.PI)) {
                return {
                    position: 'absolute',
                    left: '-400px',
                    top: '-400px',
                    zIndex: 1,
                }
            }
            return {
                position: 'absolute',
                transform: `translate(calc(-50% + ${
                    this.positions[item.goettItem.PI].x
                }px), calc(-50% + ${this.positions[item.goettItem.PI].y}px))`,
                zIndex: 1,
                border:
                    debug &&
                    this.$store.state.cupboardsGameHaystack.item.goettItem
                        .PI === item.goettItem.PI
                        ? '3px solid red'
                        : 'none',
                // left: `${this.positions[item.goettItem.PI].x}px`,
                // top: `${this.positions[item.goettItem.PI].y}px`,
            }
        },
        getDebugClass() {
            return {
                included: this.$store.getters[
                    'cupboardsGameHaystack/isSelectedItemInFilteredItems'
                ],
            }
        },
        infoClosed() {
            switch (this.$store.getters['cupboardsGameHaystack/getStep']) {
                case 'INTRODUCTION':
                    this.start()
                    break
                case 'GAMEOVER':
                    this.close()
                    break
            }
        },
        start() {
            this.$store.dispatch('cupboardsGameHaystack/setNextStep')
        },
        close() {
            this.$store.dispatch('cupboards/setGame', GAMES.NONE)
            this.$store.dispatch('cupboardsGameHaystack/resetFilters')
        },
        getLayer() {
            const timestamp = Date.now()
            return (
                require(`../../../../assets/animations/fowi-confetti.png`) +
                '?' +
                timestamp
            )
        },
        areLivesShown() {
            return (
                this.$store.getters['cupboardsGameHaystack/isStep'](
                    STEPS.FIND,
                ) ||
                this.$store.getters['cupboardsGameHaystack/isStep'](
                    STEPS.RESULT,
                ) ||
                this.$store.getters['cupboardsGameHaystack/isStep'](
                    STEPS.GAMEOVER,
                )
            )
        },
    },
}
</script>
<style scoped lang="scss">
.haystack {
    display: flex;
    flex-direction: column;

    .livesText {
        font-size: calc(32vw * var(--pxToVw));
    }

    .lives {
        display: flex;
        flex-direction: row;
        z-index: 2;

        .life {
            margin-left: calc(16vw * var(--pxToVw));
            fill: var(--secondary);
        }
    }

    .content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        .texts {
            padding-left: calc(50vw * var(--pxToVw));
            font-size: calc(32vw * var(--pxToVw));
            height: calc(100vw * var(--pxToVw));
        }
        .interactive-area {
            flex-grow: 1;
            width: 100%;
            display: flex;
            aside {
                width: 25%;
                .confetti {
                    width: 100%;
                    padding-left: 50px;
                    img {
                        width: 100%;
                    }
                }
                .filters {
                    padding-left: 50px;
                    width: 100%;
                }
            }
        }

        .items {
            width: 75%;
            margin-right: calc(50vw * var(--pxToVw));

            img {
                transform: translate(-50%, -50%);
                width: calc(32vw * var(--pxToVw));
                height: calc(32vw * var(--pxToVw));
                clip-path: circle(50%);

                &.disabled {
                    pointer-events: none;
                    opacity: 0.2;
                }
            }
        }
    }
}

.debug {
    position: absolute;
    right: 0;
    top: calc(200vw * var(--pxToVw));
    right: calc(50vw * var(--pxToVw));
    background-color: var(--red);
    z-index: 2;
    font-size: calc(48vw * var(--pxToVw));

    &.included {
        background-color: var(--green);
    }
}

.introduction {
    background-color: blue;
    z-index: 10;

    .itemInfo {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}

.featuredItem {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
}

.hullContainer {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    z-index: 0;
}

.haystack .content .items .single-layer {
    position: fixed;
    width: calc(400vw * var(--pxToVw));
    height: calc(400vw * var(--pxToVw));

    img {
        width: 100%;
        height: auto;
        transform: translateX(0) translateY(-20%);
    }
}
</style>
