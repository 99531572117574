<template>
    <div class="range-slider" slider>
        <div>
            <div inverse-left style="width:100%;"></div>
            <div inverse-right style="width:100%;"></div>
            <div range :style="rangeStyle"></div>
            <span thumb :style="thumbMinStyle"></span>
            <span thumb :style="thumbMaxStyle"></span>
            <span
                :class="{ single: sliderMax === sliderMin }"
                class="label minLabel align-baseline absolute"
                :style="thumbMinStyle"
            >
                {{ sliderMin }}
            </span>
            <span
                v-if="sliderMax > sliderMin"
                class="label maxLabel align-baseline absolute"
                :style="thumbMaxStyle"
            >
                {{ sliderMax }}
            </span>
        </div>

        <input
            v-model="sliderMin"
            class="min"
            type="range"
            :min="min"
            :max="max"
            :step="step"
        />
        <input
            v-model="sliderMax"
            class="max"
            type="range"
            :min="min"
            :max="max"
            :step="step"
        />
        <!--        <span class="label minLabel">{{ sliderMin }}</span>-->
        <!--        <span class="label maxLabel">{{ sliderMax }}</span>-->
    </div>
</template>

<script>
export default {
    name: 'RangeSlider',
    props: {
        value: {
            type: Object,
            default: () => {
                return { min: 1, max: 1 }
            },
        },
        min: {
            type: Number,
            default: () => 0,
        },
        max: {
            type: Number,
            default: () => 1,
        },
        step: {
            type: Number,
            default: () => 1,
        },
    },
    emits: ['valueChange'],
    data() {
        return {
            rangeValue: 0,
            newMinValue: this.value.min,
            newMaxValue: this.value.max,
            thumbMinStyle: 'left:0%',
            thumbMaxStyle: 'left:100%',
            rangeStyle: 'left:0%;right:0%',
            delay: null,
        }
    },
    computed: {
        sliderMin: {
            get: function() {
                let val = this.newMinValue
                return val
            },
            set: function(val) {
                if (val >= this.newMaxValue) {
                    this.newMaxValue = Math.floor(val)
                }
                this.newMinValue = Math.floor(val)
                //
                let value =
                    ((this.newMinValue - this.min) / (this.max - this.min)) *
                    100

                this.thumbMinStyle = 'left:' + value + '%'
                if (this.newMinValue >= this.newMaxValue) {
                    this.thumbMaxStyle = 'left:' + value + '%'
                }
                //
                if (this.newMinValue >= this.newMaxValue) {
                    const minValue = this.newMaxValue
                    this.newMaxValue = this.newMinValue
                    this.newMinValue = minValue
                }

                let valueMax =
                    ((this.max - this.newMaxValue) / (this.max - this.min)) *
                    100

                this.rangeStyle = 'left:' + value + '%;right:' + valueMax + '%'
                if (this.delay) {
                    clearTimeout(this.delay)
                }

                this.delay = setTimeout(() => {
                    this.$emit('valueChanged', {
                        min: this.newMinValue,
                        max: this.newMaxValue,
                    })
                }, 500)
            },
        },
        sliderMax: {
            get: function() {
                let val = this.newMaxValue
                return val
            },
            set: function(val) {
                if (val <= this.newMinValue) {
                    this.newMinValue = Math.floor(val)
                }

                this.newMaxValue = Math.floor(val)

                let value =
                    ((this.newMaxValue - this.min) / (this.max - this.min)) *
                    100

                this.thumbMaxStyle = 'left:' + value + '%'
                // if (this.newMaxValue >= this.newMinValue) {
                //     this.thumbMinStyle = 'left:' + value + '%'
                // }
                if (this.newMinValue >= this.newMaxValue) {
                    this.thumbMinStyle = 'left:' + value + '%'
                }
                if (this.newMinValue >= this.newMaxValue) {
                    const minValue = this.newMaxValue
                    this.newMaxValue = this.newMinValue
                    this.newMinValue = minValue
                }

                let valueMin =
                    ((this.newMinValue - this.min) / (this.max - this.min)) *
                    100
                this.rangeStyle =
                    'left:' + valueMin + '%;right:' + (100 - value) + '%'

                if (this.delay) {
                    clearTimeout(this.delay)
                }
                this.delay = setTimeout(() => {
                    this.$emit('valueChanged', {
                        min: this.newMinValue,
                        max: this.newMaxValue,
                    })
                }, 500)
            },
        },
    },
    mounted() {
        window.addEventListener('contextmenu', e => e.preventDefault())
        window.oncontextmenu = function(event) {
            event.preventDefault()
            event.stopPropagation()
            return false
        }
    },
    methods: {
        updateSlider(value) {
            // alert(value)
            if (this.sliderMin - value > this.sliderMax - value) {
                this.sliderMin = value
            } else {
                this.sliderMax = value
            }
        },
    },
}
</script>

<style lang="scss" scoped>
$backgroundcolor: var(--semilightgrey);
[slider] {
    position: relative;
    height: 14px;
    border-radius: 10px;
    text-align: left;
    margin: 20px 0px 50px 0px;
    /* width: calc(100% - 80px); */
}

[slider] > div {
    position: absolute;
    left: 13px;
    right: 15px;
    height: 14px;
}

[slider] > div > [inverse-left] {
    position: absolute;
    left: 0;
    height: 10px;
    border-radius: 0;
    background-color: #ccc;
    margin: 0 5px;
}

[slider] > div > [inverse-right] {
    position: absolute;
    right: 0;
    height: 0px;
    border-radius: 10px;
    background-color: #ccc;
    margin: 0 5px;
}

[slider] > div > [range] {
    position: absolute;
    left: 0;
    height: 10px;
    //border-radius: 14px;
    background-color: $backgroundcolor;
}

[slider] > div > [thumb] {
    position: absolute;
    top: -12px;
    z-index: 2;
    height: 32px;
    width: 32px;
    text-align: left;
    margin-left: -11px;
    cursor: pointer;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.4);
    background-color: var(--white);
    //border-radius: 50%;
    outline: none;
    border: 3px solid #939393;
}

[slider] > input[type='range'] {
    position: absolute;
    pointer-events: none;
    -webkit-appearance: none;
    z-index: 3;
    height: 10px;
    top: -2px;
    width: 100%;
    opacity: 0;
}

div[slider] > input[type='range']::-ms-track {
    -webkit-appearance: none;
    background: transparent;
    color: transparent;
}

div[slider] > input[type='range']::-moz-range-track {
    -moz-appearance: none;
    background: transparent;
    color: transparent;
}

div[slider] > input[type='range']:focus::-webkit-slider-runnable-track {
    background: transparent;
    border: transparent;
}

div[slider] > input[type='range']:focus {
    outline: none;
}

div[slider] > input[type='range']::-ms-thumb {
    pointer-events: all;
    width: 28px;
    height: 28px;
    border-radius: 0px;
    border: 0 none;
    background: red;
}

div[slider] > input[type='range']::-moz-range-thumb {
    pointer-events: all;
    width: 28px;
    height: 28px;
    border-radius: 0px;
    border: 0 none;
    background: var(--red);
}

div[slider] > input[type='range']::-webkit-slider-thumb {
    pointer-events: all;
    width: 28px;
    height: 28px;
    border-radius: 0px;
    border: 0 none;
    background: var(--red);
    -webkit-appearance: none;
}

div[slider] > input[type='range']::-ms-fill-lower {
    background: transparent;
    border: 0 none;
}

div[slider] > input[type='range']::-ms-fill-upper {
    background: transparent;
    border: 0 none;
}

div[slider] > input[type='range']::-ms-tooltip {
    display: none;
}

[slider] > div > [sign] {
    opacity: 0;
    position: absolute;
    margin-left: -11px;
    top: -39px;
    z-index: 3;
    background-color: $backgroundcolor;
    color: var(--white);
    width: 28px;
    height: 28px;
    border-radius: 28px;
    -webkit-border-radius: 28px;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    text-align: center;
}

[slider] > div > [sign]:after {
    position: absolute;
    content: '';
    left: 0;
    border-radius: 16px;
    top: 19px;
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    border-top-width: 16px;
    border-top-style: solid;
    border-top-color: $backgroundcolor;
}

[slider] > div > [sign] > span {
    font-size: 12px;
    font-weight: 700;
    line-height: 28px;
}

[slider]:hover > div > [sign] {
    opacity: 1;
}

.minLabel,
.maxLabel {
    width: auto;
    position: absolute;
    top: 50%;
    transform: translateY(0%);
    font-size: 20px;
    margin-top: 15px;
}

.minLabel {
    margin-left: 0;
    left: 0;
    padding: 3px;
    transform: translateX(calc(-50% + 6px));
    &.single {
        transform: translateX(-50%);
    }
}

.maxLabel {
    padding: 3px;
    right: 0;
    transform: translateX(calc(-50% + 6px));
    display: inline-flex;
    justify-content: center;
    width: 10ch;
}

* {
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
}

input[type='range'] {
    pointer-events: none;
}

input[type='range']::-webkit-slider-thumb {
    pointer-events: auto;
}

input[type='range']::-moz-range-thumb {
    pointer-events: auto;
}

input[type='range']::-ms-thumb {
    pointer-events: auto;
}
</style>
