<template>
    <div class="header">
        <div class="station-title-container d-inline-flex">
            <h1 class="station-title">
                {{
                    gameTitle !== '' ? gameTitle : $t('cupboards_station_title')
                }}
            </h1>
        </div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'CupboardsGameHeader',
    components: {},
    props: {
        gameTitle: {
            type: String,
            required: false,
            default: () => '',
        },
    },
    data() {
        return {}
    },
    computed: {},
}
</script>

<style scoped lang="scss">
.header {
    padding: calc(50vw * var(--pxToVw));
    display: flex;

    .station-title-container {
        flex-grow: 1;

        .station-title {
            position: relative;
            transform: none;
            left: 0;
            top: 0;
        }
    }
}
</style>
