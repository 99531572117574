<template>
    <div class="scroller">
        <div class="inner">
            <div class="scroll-navi" :class="{ visible: arrows && scrollable }">
                <button class="py-0 px-2 m-0" @click="scrollByStep('up')">
                    <font-awesome-icon :icon="['far', 'caret-up']" size="sm" />
                </button>
                <button @click="scrollByStep('down')">
                    <font-awesome-icon
                        :icon="['far', 'caret-down']"
                        size="sm"
                    />
                </button>
            </div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Scroller',
    props: {
        arrows: {
            type: Boolean,
            default: false,
        },
        elem: {
            type: String,
            default: '',
        },
        step: {
            type: Number,
            default: 200,
        },
    },
    data() {
        return { scrollable: false }
    },
    mounted() {
        let scrollbarElement = 'inner'
        if (this.elem) {
            scrollbarElement = this.elem
        }
        let scrollableElement = document.querySelector('.' + scrollbarElement)
        scrollableElement.addEventListener('scroll', () => {
            this.$emit('scroll-top', scrollableElement.scrollTop)
        })

        this.scrollable =
            scrollableElement.scrollHeight > scrollableElement.clientHeight
    },
    methods: {
        scrollByStep(direction) {
            let selectList = document.querySelector('.scroller .inner')
            if (direction === 'up') {
                selectList.scrollTop -= this.step
            } else {
                selectList.scrollTop += this.step
            }
        },
    },
}
</script>

<style lang="scss">
.scroller {
    /* width */
    height: 100%;
    overflow-y: auto;
    position: relative;

    .inner::-webkit-scrollbar {
        width: calc(10vw * var(--pxToVw));
        padding-right: calc(10vw * var(--pxToVw));
    }

    .inner {
        height: 100%;
        overflow-y: auto;

        .scroll-navi {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            display: none;
            flex-wrap: wrap;
            align-content: space-between;
            z-index: 999999;
            flex-direction: column;
            justify-content: space-between;

            &.visible {
                display: flex;
            }

            button {
                width: calc(30vw * var(--pxToVw)) !important;
                height: calc(30vw * var(--pxToVw)) !important;
            }
        }
    }

    /* Track */
    .inner::-webkit-scrollbar-track {
        background: var(--lightgrey);
    }

    /* Handle */
    .inner::-webkit-scrollbar-thumb {
        background: #ccc;
    }

    /* Handle on hover */
    .inner::-webkit-scrollbar-thumb:hover {
        background: #ccc;
    }
}
</style>
