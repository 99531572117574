<template>
    <div>
        <particles
            :color="theme === 'white' ? '#000' : '#dedede'"
            :lines-color="theme === 'white' ? '#000' : '#dedede'"
            :particle-opacity="0.7"
            :particles-number="80"
            shape-type="circle"
            :particle-size="4"
            :lines-width="1"
            :line-linked="true"
            :line-opacity="0.4"
            :lines-distance="150"
            :move-speed="3"
            :hover-effect="true"
            hover-mode="grab"
            :click-effect="true"
            click-mode="push"
            class="particles"
        ></particles>
        <h1 class="station-title de">{{ $t('cupboards_station_title_de') }}</h1>
        <h1 class="station-title en">{{ $t('cupboards_station_title_en') }}</h1>

        <p class="instruction de">
            {{ $t('cupboards_idle_instruction_de') }}
        </p>
        <p class="instruction en">
            {{ $t('cupboards_idle_instruction_en') }}
        </p>
        <div
            class="position-absolute w-100 h-100 d-flex justify-content-center"
        >
            <div
                class="d-flex w-75 m-0 flex-wrap animation justify-content-center align-content-center"
            >
                <div
                    v-for="(letter, i) in splitNameIntoWordArray"
                    :key="`${names[index]}-${index}_letter_ + ${i}`"
                    class="d-inline display-1"
                    :class="['letter-' + i, letter === '-' ? '' : 'mx-3']"
                    v-html="letter !== ' ' ? letter : '&nbsp;'"
                ></div>
            </div>
        </div>
    </div>
</template>

<script>
import api from '../../../mixins/api'
import Animation from './idle/Animation.vue'
import Particles from './idle/Particles.vue'
import { stationsData } from '../../../stationsData'

const cupboardsStationData = stationsData.find(item => item.key === 'cupboards')

export default {
    name: 'CupboardsIdle',
    components: {
        Animation,
        Particles,
    },
    data() {
        return {
            intervalId: -1,
            names: [],
            index: 0,
            theme: cupboardsStationData.theme,
        }
    },
    computed: {
        splitNameIntoCharArray() {
            if (this.names.length === 0) {
                return []
            }
            const chars = this.names[this.index].split('')
            return chars
        },
        splitNameIntoWordArray() {
            if (this.names.length === 0) {
                return []
            }
            let words = this.names[this.index]
                .split('-')
                .join(' - ')
                .split(' ')

            return words
        },
    },
    async mounted() {
        this.$store.dispatch('cupboards/resetState')
        this.$store.dispatch('cupboards/getCollectionNames')
        this.names = (
            await api.twoavy.getCupboardsCollectionNames()
        ).data.data.map(name => name.payload.name)
        this.intervalId = setInterval(() => {
            if (this.index >= this.names.length - 1) {
                this.index = 0
            } else {
                this.index = this.index + 1
            }
        }, 7000)
    },
    beforeDestroy: function() {
        clearInterval(this.intervalId)
    },
    methods: {},
}
</script>

<style scoped lang="scss">
@function randomNum($min, $max) {
    $rand: random();
    $randomNum: $min + floor($rand * (($max - $min) + 1));

    @return $randomNum;
}

@for $i from 0 through 90 {
    .letter-#{$i} {
        animation: randomLetterMovement-#{$i} ease-in-out 7s;
    }
    @keyframes randomLetterMovement-#{$i} {
        0% {
            transform: translate(
                randomNum(-4000, 4000) + px,
                randomNum(-4000, 4000) + px
            );
            opacity: 0;
        }
        10% {
            opacity: 1;
        }
        40% {
            transform: translate(0, 0);
        }
        85% {
            transform: translate(0, 0);
            opacity: 1;
        }
        100% {
            transform: translate(
                randomNum(-4000, 4000) + px,
                randomNum(-4000, 4000) + px
            );
            opacity: 0;
        }
    }
}

.particles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.station-title {
    position: absolute;
    left: calc(50vw * var(--pxToVw));

    &.de {
        top: calc(50vw * var(--pxToVw));
    }

    &.en {
        top: calc(108vw * var(--pxToVw));
    }
}

.instruction {
    font-size: calc(30vw * var(--pxToVw));
    position: absolute;
    left: calc(50vw * var(--pxToVw));
    width: calc(500vw * var(--pxToVw));

    &.de {
        top: calc(190vw * var(--pxToVw));
    }

    &.en {
        top: calc(336vw * var(--pxToVw));
        font-size: calc(24vw * var(--pxToVw));
        color: var(--semilightgrey);
    }
}

.animation {
    color: var(--secondary);
}
</style>
