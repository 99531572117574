<template>
    <div class="radio" @click="onClick">
        <div v-if="selected">
            <input type="radio" :name="`${group}_selected`" :checked="true" />
        </div>
        <div v-else>
            <input
                type="radio"
                :name="`${group}_unselected`"
                :checked="false"
            />
        </div>
    </div>
</template>
<script>
export default {
    name: 'CupboardsGameGeneratorRadio',
    props: {
        selected: {
            type: Boolean,
            required: true,
        },
        group: {
            type: String,
            required: true,
        },
    },
    emits: ['valueChanged'],
    data() {
        return {}
    },

    methods: {
        onRadioChange(event) {
            this.$emit('valueChanged', event)
        },
        onClick(event) {
            this.$emit('valueChanged', event)
        },
    },
}
</script>
<style lang="scss" scoped>
.radio {
    display: flex;
}
</style>
