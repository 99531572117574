<template>
    <div class="filters">
        <img
            :src="$store.state.cupboardsGameGenerator.item.thumbnailUrl"
            class="item"
            @click="showItemInfo()"
        />
        <filter-slider>
            <category-filter
                :label="'objectclass'"
                :category="FIELDS.MD_UNIGOE_OBJEKTGATTUNG"
            ></category-filter>
            <category-filter
                :label="'century'"
                :category="FIELDS.CENTURY"
            ></category-filter>
            <category-filter
                :label="'material'"
                :category="FIELDS.MD_UNIGOE_MATERIAL_INDEX"
            ></category-filter>

            <category-filter
                :label="'keyword'"
                :category="FIELDS.MD_UNIGOE_SCHLAGWORTE"
            ></category-filter>

            <category-filter
                :label="'place'"
                :category="FIELDS.MD_UNIGOE_ORTE"
            ></category-filter>
            <category-filter
                :label="'MD_UNIGOE_PERSONEN'"
                :category="FIELDS.MD_UNIGOE_PERSONEN"
            ></category-filter>
        </filter-slider>
    </div>
</template>
<script>
import { STEPS } from '../../../../store/stations/3.001_cupboards/gameGenerator'
import { FIELDS } from '../../../../mixins/api'
import Filter from './Filter.vue'
import FilterSlider from '../../../global/FilterSlider.vue'

export default {
    name: 'CupboardsGameGeneratorFilters',
    components: { CategoryFilter: Filter, FilterSlider },
    data() {
        return {
            FIELDS,
            STEPS,
        }
    },

    async mounted() {
        this.$store.dispatch('cupboardsGameGenerator/clearSelectedProps')
    },
    methods: {
        showItemInfo() {
            this.$store.dispatch('cupboardsGameGenerator/clearSelectedProps')
        },
    },
}
</script>
<style lang="scss" scoped>
.filters {
    width: 100%;
    height: 500px;
    overflow-y: scroll;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    font-size: 32px;
    text-align: center;

    .item {
        height: 80%;
        width: auto;
    }

    .filter {
        /* flex: 1 1 0px; */
        .name {
            font-weight: bold;
            font-size: 36px;
        }

        text-align: left;
        padding-left: 16px;
        padding-right: 16px;
        margin-left: 16px;
        margin-right: 16px;

        &.selected {
            //background-color: rgb(72, 29, 117);
            /* item info header style */
            //border: 3px solid white;
            /* background-color: var(--mediumgrey); */
            border-top: 5px solid var(--secondary);
            background-color: transparent;
        }
    }
}
</style>
