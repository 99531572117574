<template>
    <div ref="cupboards" class="h-100 w-100 game overflow-hidden">
        <login-timer
            :unit="$route.name"
            :user-uuid="$store.getters.getUserAtUnit($route.name).uuid"
        />

        <div
            v-show="$store.state.cupboards.game === GAMES.NONE"
            class="h-100 w-100"
        >
            <h1 class="station-title">
                {{ $t('cupboards_station_title') }}
            </h1>
            <!--<fowi-drag-and-drop-zone
                :items="$store.getters.getUserAtUnit($route.name).collection"
                :zone-one-items="
                    getGeneratorItemsPIs().filter(PI =>
                        $store.getters
                            .getUserAtUnit($route.name)
                            .collection.find(
                                collectedItem => collectedItem.id === PI,
                            ),
                    )
                "
                :zone-two-items="$store.getters['cupboards/getTaxonomyItemPIs']"
                :zone-three-items="
                    getHaystackItemsPIs().filter(PI =>
                        $store.getters
                            .getUserAtUnit($route.name)
                            .collection.find(
                                collectedItem => collectedItem.id === PI,
                            ),
                    )
                "
                @dropZone="onGameSelect"
            >-->
            <fowi-drag-and-drop-zone
                :items="$store.getters.getUserAtUnit($route.name).collection"
                :zone-one-items="$store.getters['cupboards/getTaxonomyItemPIs']"
                :zone-two-items="
                    getHaystackItemsPIs().filter(PI =>
                        $store.getters
                            .getUserAtUnit($route.name)
                            .collection.find(
                                collectedItem => collectedItem.id === PI,
                            ),
                    )
                "
                @dropZone="onGameSelect"
            >
                <div slot="dropZone1">
                    <h2>{{ $t('cupboards_generator_title') }}</h2>
                    <p>{{ $t('cupboards_generator_description') }}</p>
                </div>
                <div slot="dropZone2">
                    <h2>{{ $t('cupboards_taxonomy_title') }}</h2>
                    <p>{{ $t('cupboards_taxonomy_description') }}</p>
                </div>
                <div slot="dropZone3">
                    <h2>{{ $t('cupboards_haystack_title') }}</h2>
                    <p>{{ $t('cupboards_haystack_description') }}</p>
                </div>
            </fowi-drag-and-drop-zone>
            <p
                v-if="$store.state.cupboards.game === GAMES.NONE"
                class="instructions"
            >
                {{ $t('cupboards_instructions') }}
            </p>
        </div>
        <div
            v-show="$store.state.cupboards.game !== GAMES.NONE"
            class="fill-parent"
        >
            <cupboards-game-generator
                v-if="$store.state.cupboards.game === GAMES.GENERATOR"
            />
            <cupboards-game-haystack
                v-if="$store.state.cupboards.game === GAMES.HAYSTACK"
            />
            <cupboards-game-taxonomy
                v-if="$store.state.cupboards.game === GAMES.TAXONOMY"
            />
        </div>
    </div>
</template>

<script>
import { GAMES } from '@/store/stations/3.001_cupboards/cupboards'
import CupboardsGameGenerator from './generator/CupboardsGameGenerator.vue'
import CupboardsGameHaystack from './haystack/CupboardsGameHaystack.vue'
import CupboardsGameTaxonomy from './taxonomy/CupboardsGameTaxonomy.vue'
import FowiDragAndDropZone from '@/components/global/FowiDragAndDropZone.vue'
import LoginTimer from '@/components/global/LoginTimer.vue'

export default {
    name: 'CupboardsGame',
    components: {
        LoginTimer,
        CupboardsGameGenerator,
        CupboardsGameHaystack,
        CupboardsGameTaxonomy,
        FowiDragAndDropZone,
    },
    data() {
        return {
            GAMES,
        }
    },
    computed: {
        collectedItemsPIs() {
            const items = this.$store.getters
                .getUserAtUnit(this.$route.name)
                .collection.map(item => item?.goettItem?.PI)
            return items ? items : null
        },
    },
    methods: {
        onGameSelect(event) {
            switch (event.zone) {
                case 'drop-zone-1':
                    this.$store.dispatch('cupboardsGameGenerator/resetState')
                    this.$store.dispatch('cupboards/setGame', GAMES.GENERATOR)
                    this.$store.dispatch(
                        'cupboardsGameGenerator/setItem',
                        event.item,
                    )
                    break

                case 'drop-zone-2':
                    this.$store.dispatch('cupboardsGameTaxonomy/resetState')
                    this.$store.dispatch('cupboards/setGame', GAMES.TAXONOMY)
                    this.$store.dispatch(
                        'cupboardsGameTaxonomy/setItem',
                        event.item,
                    )
                    break
                case 'drop-zone-3':
                    this.$store.dispatch('cupboardsGameHaystack/resetState')
                    this.$store
                        .dispatch('cupboardsGameHaystack/setItem', event.item)
                        .then(() => {
                            this.$store.dispatch(
                                'cupboards/setGame',
                                GAMES.HAYSTACK,
                            )
                        })
                    break
            }
        },
        getGeneratorItemsPIs() {
            return this.$store.getters
                .getUserAtUnit(this.$route.name)
                .collection.map(item =>
                    item?.goettItem ? item.goettItem.PI : null,
                )
        },
        getHaystackItemsPIs() {
            const items = this.$store.getters['cupboards/getHaystackItemPIs']
            return items
        },
    },
}
</script>

<style scoped lang="scss">
.game {
    color: var(--secondary);
}

.instructions {
    position: absolute;
    left: calc(50vw * var(--pxToVw));
    top: calc(154vw * var(--pxToVw));
    font-size: calc(30vw * var(--pxToVw));
    font-weight: 300;
    width: calc(500vw * var(--pxToVw));
}
</style>
