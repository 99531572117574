var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"haystack h-100 w-100"},[_c('cupboards-game-header',{staticClass:"d-flex w-100 justify-content-between",attrs:{"game-title":_vm.$t('cupboards_haystack_title')}},[(_vm.areLivesShown())?_c('div',{staticClass:"livesText d-flex align-items-center"},[_vm._v(" "+_vm._s(_vm.$t('haystack_lives_hint', { count: _vm.$store.getters[ 'cupboardsGameHaystack/getRemainingTries' ], }))+" ")]):_vm._e(),(_vm.areLivesShown())?_c('div',{staticClass:"lives d-flex align-items-center"},[_vm._l((3 -
                    _vm.$store.getters[
                        'cupboardsGameHaystack/getRemainingTries'
                    ]),function(i){return _c('font-awesome-icon',{key:`life-lost-${i}`,staticClass:"life",attrs:{"size":"2x","icon":['fas', 'frown']}})}),_vm._l((_vm.$store.getters[
                    'cupboardsGameHaystack/getRemainingTries'
                ]),function(i){return _c('font-awesome-icon',{key:`life-remaining-${i}`,staticClass:"life",attrs:{"size":"2x","icon":['far', 'smile']}})})],2):_vm._e()]),_c('div',{staticClass:"content"},[_c('texts',{staticClass:"texts"}),(
                _vm.$store.getters['cupboardsGameHaystack/isStep'](
                    _vm.STEPS.INTRODUCTION,
                ) ||
                    (_vm.$store.getters['cupboardsGameHaystack/isStep'](
                        _vm.STEPS.GAMEOVER,
                    ) &&
                        _vm.$store.state.cupboardsGameHaystack.selectResult ===
                            _vm.SELECTRESULTS.SUCCESS)
            )?_c('div',{staticClass:"introduction"},[_c('item-info',{staticClass:"itemInfo",attrs:{"thumbnail":_vm.api.goettingen.getIsolatedThumbnailUrl(
                        _vm.$store.state.cupboardsGameHaystack.item.goettItem,
                        512,
                    ),"has-close-button":false,"item":_vm.$store.state.cupboardsGameHaystack.item,"goett-fields":[
                    _vm.FIELDS.MD_UNIGOE_OBJEKTGATTUNG,
                    _vm.FIELDS.MD_UNIGOE_MATERIAL_ANZEIGE,
                    _vm.FIELDS.MD_UNIGOE_PERSONEN,
                ],"custom-fields":_vm.customFields},on:{"info-closed":function($event){return _vm.infoClosed()}}})],1):_vm._e(),_c('div',{staticClass:"interactive-area"},[_c('aside',[(_vm.showConfetti)?_c('div',{staticClass:"single-layer mt-5 success confetti"},[_c('img',{attrs:{"src":_vm.getLayer(),"alt":""}})]):_c('filters',{staticClass:"filters"})],1),_c('div',{ref:"matter",staticClass:"items"},[_c('div',_vm._l((_vm.$store.state.cupboardsGameHaystack
                            .items),function(item){return _c('img',{directives:[{name:"long-press",rawName:"v-long-press",value:(300),expression:"300"}],key:`item-${item.goettItem.PI}`,class:{
                            disabled: !_vm.$store.getters[
                                'cupboardsGameHaystack/isItemInFilteredItems'
                            ](item),
                        },style:(_vm.getItemStyle(item)),attrs:{"src":_vm.$store.getters['items/getIsolatedBlob']({
                                item,
                                width: 100,
                            })},on:{"click":function($event){return _vm.itemSelected(item)},"long-press-start":function($event){return _vm.onLongPressStart(item, $event)},"long-press-stop":function($event){return _vm.onLongPressStop(item, $event)}}})}),0)])])],1),_c('cupboards-game-footer',{staticClass:"footer"},[_c('template',{slot:"actions"},[(
                    _vm.$store.getters['cupboardsGameHaystack/isStep'](
                        _vm.STEPS.INTRODUCTION,
                    )
                )?_c('div',{staticClass:"button startButton clickable",on:{"click":_vm.infoClosed}},[_vm._v(" starten ")]):_vm._e()])],2),(_vm.$store.getters['cupboardsGameHaystack/isStep'](_vm.STEPS.RESULT))?_c('div',{staticClass:"result"}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }