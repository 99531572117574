var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gameGenerator fill-parent"},[_c('cupboards-game-header',{attrs:{"game-title":_vm.$t('cupboards_generator_title')}}),(_vm.$store.state.cupboardsGameGenerator.step === _vm.STEPS.CONCLUSION)?_c('box-wrapper',{staticClass:"popup",attrs:{"title":'outro'}},[_c('template',{slot:"button"},[_c('div',{staticClass:"fowi-btn fowi-btn-primary fowi-btn-sq48",on:{"click":_vm.onPopupClosed}},[_c('font-awesome-icon',{attrs:{"icon":['far', 'times'],"size":"2x"}})],1)]),_c('template',{slot:"content"},[_c('div',{staticClass:"popup-content"},[_vm._v(" "+_vm._s(_vm.$t('cupboards_generator_outro'))+" ")])])],2):_vm._e(),_c('div',{staticClass:"content"},[_c('div',{staticClass:"left"},[_c('div',{staticClass:"header"},[_c('img',{staticClass:"thumbnail",attrs:{"src":_vm.$store.getters['items/getBlob']({
                            item: _vm.$store.state.cupboardsGameGenerator.item,
                            width: 200,
                        }),"width":"200px","height":"auto"}}),_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$store.state.cupboardsGameGenerator.item.goettItem .LABEL)+" ")])]),_c('div',{staticClass:"text-categories"},[_vm._v(" "+_vm._s(_vm.$t('cupboards_generator_categories'))+" ")]),(_vm.$store.state.cupboardsGameGenerator.item)?_c('ul',{staticClass:"filters"},[(
                        _vm.$store.getters[
                            'cupboardsGameGenerator/hasCategoryEnoughRelatedItems'
                        ](_vm.FIELDS.MD_UNIGOE_OBJEKTGATTUNG)
                    )?_c('li',[_c('category-filter',{attrs:{"category":_vm.FIELDS.MD_UNIGOE_OBJEKTGATTUNG,"label":_vm.FIELDS.MD_UNIGOE_OBJEKTGATTUNG}})],1):_vm._e(),(
                        _vm.$store.getters[
                            'cupboardsGameGenerator/hasCategoryEnoughRelatedItems'
                        ](_vm.FIELDS.CENTURY)
                    )?_c('li',[_c('category-filter',{attrs:{"category":_vm.FIELDS.CENTURY,"label":_vm.FIELDS.CENTURY}})],1):_vm._e(),(
                        _vm.$store.getters[
                            'cupboardsGameGenerator/hasCategoryEnoughRelatedItems'
                        ](_vm.FIELDS.MD_UNIGOE_MATERIAL_INDEX)
                    )?_c('li',[_c('category-filter',{attrs:{"category":_vm.FIELDS.MD_UNIGOE_MATERIAL_INDEX,"label":_vm.FIELDS.MD_UNIGOE_MATERIAL_INDEX}})],1):_vm._e(),(
                        _vm.$store.getters[
                            'cupboardsGameGenerator/hasCategoryEnoughRelatedItems'
                        ](_vm.FIELDS.MD_UNIGOE_SCHLAGWORTE)
                    )?_c('li',[_c('category-filter',{attrs:{"category":_vm.FIELDS.MD_UNIGOE_SCHLAGWORTE,"label":_vm.FIELDS.MD_UNIGOE_SCHLAGWORTE}})],1):_vm._e(),(
                        _vm.$store.getters[
                            'cupboardsGameGenerator/hasCategoryEnoughRelatedItems'
                        ](_vm.FIELDS.MD_UNIGOE_ORTE)
                    )?_c('li',[_c('category-filter',{attrs:{"category":_vm.FIELDS.MD_UNIGOE_ORTE,"label":_vm.FIELDS.MD_UNIGOE_ORTE}})],1):_vm._e(),(
                        _vm.$store.getters[
                            'cupboardsGameGenerator/hasCategoryEnoughRelatedItems'
                        ](_vm.FIELDS.MD_UNIGOE_PERSONEN)
                    )?_c('li',[_c('category-filter',{attrs:{"category":_vm.FIELDS.MD_UNIGOE_PERSONEN,"label":_vm.FIELDS.MD_UNIGOE_PERSONEN}})],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"instruction"},[_vm._v(" "+_vm._s(_vm.$t('cupboards_generator_instruction_categories'))+" ")])]),(_vm.$store.state.cupboardsGameGenerator.selectedCategory)?_c('div',{staticClass:"right"},[_c('visualisation',{staticClass:"visualisation"}),_c('related-items',{staticClass:"related-items"})],1):_vm._e()]),_c('cupboards-game-footer',{staticClass:"footer",attrs:{"custom-close":true},on:{"close":_vm.onCloseButtonClicked}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }