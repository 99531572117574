var render = function render(){var _vm=this,_c=_vm._self._c;return (
        _vm.$store.getters['cupboardsGameGenerator/hasItemCategory'](_vm.category)
    )?_c('div',{staticClass:"filter"},[_c('div',{staticClass:"label"},[_c('div',{staticClass:"label-value"},[_vm._v(" "+_vm._s(_vm.$t(_vm.label))+" ")]),_c('div',{staticClass:"label-suffix"},[_vm._v(":")])]),_c('div',{staticClass:"value"},[_c('ul',{staticClass:"options"},[_vm._l((_vm.$store.state.cupboardsGameGenerator.item
                    .goettItem[_vm.category]),function(option){return [(
                        _vm.$store.getters[
                            'cupboardsGameGenerator/hasCategoryAndPropEnoughRelatedItems'
                        ](_vm.category, option)
                    )?_c('li',{key:option,staticClass:"option"},[_c('div',{staticClass:"value-label",on:{"click":function($event){return _vm.setCategoryAndProp(_vm.category, option)}}},[_vm._v(" "+_vm._s(option)+" ")]),_c('radio',{attrs:{"selected":_vm.$store.getters[
                                'cupboardsGameGenerator/isCategoryAndPropSelected'
                            ](_vm.category, option),"group":`${_vm.category}_${option}`},on:{"valueChanged":function($event){return _vm.onRadioValueChanged($event, option)},"click":function($event){return _vm.setCategoryAndProp(_vm.category, option)}}})],1):_vm._e()]})],2)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }