var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"texts"},[(
            _vm.$store.getters['cupboardsGameHaystack/isStep'](
                _vm.STEPS.INTRODUCTION,
            )
        )?_c('div',[_vm._v(" "+_vm._s(_vm.$t('haystack_instruction'))+" ")]):_vm._e(),(
            _vm.$store.getters['cupboardsGameHaystack/isStep'](_vm.STEPS.FIND) &&
                _vm.$store.getters['cupboardsGameHaystack/areFiltersDirty']
        )?_c('div',[(
                _vm.$store.getters[
                    'cupboardsGameHaystack/isSelectedItemInFilteredItems'
                ]
            )?_c('span',[_vm._v(" "+_vm._s(_vm.$t('haystack_included_hint_positive'))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t('haystack_included_hint_negative'))+" ")])]):_vm._e(),(_vm.$store.getters['cupboardsGameHaystack/isStep'](_vm.STEPS.RESULT))?_c('div',[(
                _vm.$store.state.cupboardsGameHaystack.selectResult ===
                    _vm.SELECTRESULTS.MISTAKE
            )?_c('div',{staticClass:"message mistake"},[_vm._v(" "+_vm._s(_vm.$t('haystack_mistake'))+" ")]):_vm._e(),(
                _vm.$store.state.cupboardsGameHaystack.selectResult ===
                    _vm.SELECTRESULTS.SUCCESS
            )?_c('div',{staticClass:"message success"},[_vm._v(" "+_vm._s(_vm.$t('haystack_success'))+" ")]):_vm._e()]):_vm._e(),(
            _vm.$store.getters['cupboardsGameHaystack/isStep'](_vm.STEPS.GAMEOVER)
        )?_c('div',[(
                _vm.$store.state.cupboardsGameHaystack.selectResult ===
                    _vm.SELECTRESULTS.SUCCESS
            )?_c('span',[_vm._v(" "+_vm._s(_vm.$t('haystack_gameover_positive'))+" ")]):_vm._e(),(
                _vm.$store.state.cupboardsGameHaystack.selectResult ===
                    _vm.SELECTRESULTS.MISTAKE
            )?_c('span',[_vm._v(" "+_vm._s(_vm.$t('haystack_gameover_negative'))+" ")]):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }