var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"filters"},[_c('li',{staticClass:"filter"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('material'))+" ")]),_c('my-select',{ref:"select-material",attrs:{"title":"material","options":[
                    { value: null, label: _vm.$t('none_selected') },
                    ..._vm.$store.state.cupboardsGameHaystack.materialIndices.map(
                        option => {
                            return {
                                value: option,
                                label: option,
                            }
                        },
                    ),
                ]
                    .filter(_vm.uniqueFilter)
                    .sort((a, b) => {
                        if (a.label > b.label) {
                            return 1
                        } else {
                            return -1
                        }
                    }),"value":_vm.$store.getters['cupboardsGameHaystack/getMaterialIndex']},on:{"valueChanged":_vm.onMaterialIndexChange}})],1),_c('li',{staticClass:"filter"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('person'))+" ")]),_c('my-select',{ref:"select-person",attrs:{"title":"person","options":[
                    { value: null, label: _vm.$t('none_selected') },
                    ..._vm.$store.state.cupboardsGameHaystack.persons.map(
                        option => {
                            return {
                                value: option,
                                label: option,
                            }
                        },
                    ),
                ]
                    .filter(_vm.uniqueFilter)
                    .sort((a, b) => {
                        if (a.label > b.label) {
                            return 1
                        } else {
                            return -1
                        }
                    }),"value":_vm.$store.getters['cupboardsGameHaystack/getPerson']},on:{"scroll-top":_vm.scrollTopPosition,"valueChanged":_vm.onPersonChange}})],1),_c('li',{staticClass:"filter"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('authenticity'))+" ")]),_c('my-select',{ref:"select-authenticity",attrs:{"title":"authenticity","options":[
                    { value: null, label: _vm.$t('none_selected') },
                    ..._vm.$store.state.cupboardsGameHaystack.authenticities.map(
                        option => {
                            return {
                                value: option,
                                label: option,
                            }
                        },
                    ),
                ]
                    .filter(_vm.uniqueFilter)
                    .sort((a, b) => {
                        if (a.label > b.label) {
                            return 1
                        } else {
                            return -1
                        }
                    }),"value":_vm.$store.getters['cupboardsGameHaystack/getAuthenticity']},on:{"valueChanged":_vm.onAuthenticityChange}})],1),_c('li',{staticClass:"filter"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('date'))+" ")]),_c('range-slider',{attrs:{"min":parseInt(_vm.$store.state.cupboardsGameHaystack.minDate),"max":parseInt(_vm.$store.state.cupboardsGameHaystack.maxDate),"value":_vm.$store.getters['cupboardsGameHaystack/getDate'],"step":1},on:{"valueChanged":_vm.onDateChange}})],1),_c('li',{staticClass:"filter"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('weight'))+" ")]),_c('range-slider',{attrs:{"min":Math.floor(_vm.$store.state.cupboardsGameHaystack.minWeight),"max":Math.ceil(_vm.$store.state.cupboardsGameHaystack.maxWeight),"value":_vm.$store.getters['cupboardsGameHaystack/getWeight'],"step":1},on:{"valueChanged":_vm.onWeightChange}})],1),_c('li',{staticClass:"filter"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('diameter'))+" ")]),_c('range-slider',{attrs:{"min":Math.floor(_vm.$store.state.cupboardsGameHaystack.minDiameter),"max":Math.ceil(_vm.$store.state.cupboardsGameHaystack.maxDiameter),"value":_vm.$store.getters['cupboardsGameHaystack/getDiameter'],"step":1},on:{"valueChanged":_vm.onDiameterChange}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }