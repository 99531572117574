<template>
    <div class="gameGenerator fill-parent">
        <cupboards-game-header
            :game-title="$t('cupboards_generator_title')"
        ></cupboards-game-header>

        <box-wrapper
            v-if="$store.state.cupboardsGameGenerator.step === STEPS.CONCLUSION"
            class="popup"
            :title="'outro'"
        >
            <template slot="button">
                <div
                    class="fowi-btn fowi-btn-primary fowi-btn-sq48"
                    @click="onPopupClosed"
                >
                    <font-awesome-icon :icon="['far', 'times']" size="2x" />
                </div>
            </template>
            <template slot="content">
                <div class="popup-content">
                    {{ $t('cupboards_generator_outro') }}
                </div>
            </template>
        </box-wrapper>

        <div class="content">
            <div class="left">
                <div class="header">
                    <img
                        class="thumbnail"
                        :src="
                            $store.getters['items/getBlob']({
                                item: $store.state.cupboardsGameGenerator.item,
                                width: 200,
                            })
                        "
                        width="200px"
                        height="auto"
                    />
                    <div class="title">
                        {{
                            $store.state.cupboardsGameGenerator.item.goettItem
                                .LABEL
                        }}
                    </div>
                </div>
                <div class="text-categories">
                    {{ $t('cupboards_generator_categories') }}
                </div>
                <ul
                    v-if="$store.state.cupboardsGameGenerator.item"
                    class="filters"
                >
                    <li
                        v-if="
                            $store.getters[
                                'cupboardsGameGenerator/hasCategoryEnoughRelatedItems'
                            ](FIELDS.MD_UNIGOE_OBJEKTGATTUNG)
                        "
                    >
                        <category-filter
                            :category="FIELDS.MD_UNIGOE_OBJEKTGATTUNG"
                            :label="FIELDS.MD_UNIGOE_OBJEKTGATTUNG"
                        ></category-filter>
                    </li>
                    <li
                        v-if="
                            $store.getters[
                                'cupboardsGameGenerator/hasCategoryEnoughRelatedItems'
                            ](FIELDS.CENTURY)
                        "
                    >
                        <category-filter
                            :category="FIELDS.CENTURY"
                            :label="FIELDS.CENTURY"
                        ></category-filter>
                    </li>
                    <li
                        v-if="
                            $store.getters[
                                'cupboardsGameGenerator/hasCategoryEnoughRelatedItems'
                            ](FIELDS.MD_UNIGOE_MATERIAL_INDEX)
                        "
                    >
                        <category-filter
                            :category="FIELDS.MD_UNIGOE_MATERIAL_INDEX"
                            :label="FIELDS.MD_UNIGOE_MATERIAL_INDEX"
                        ></category-filter>
                    </li>
                    <li
                        v-if="
                            $store.getters[
                                'cupboardsGameGenerator/hasCategoryEnoughRelatedItems'
                            ](FIELDS.MD_UNIGOE_SCHLAGWORTE)
                        "
                    >
                        <category-filter
                            :category="FIELDS.MD_UNIGOE_SCHLAGWORTE"
                            :label="FIELDS.MD_UNIGOE_SCHLAGWORTE"
                        ></category-filter>
                    </li>
                    <li
                        v-if="
                            $store.getters[
                                'cupboardsGameGenerator/hasCategoryEnoughRelatedItems'
                            ](FIELDS.MD_UNIGOE_ORTE)
                        "
                    >
                        <category-filter
                            :category="FIELDS.MD_UNIGOE_ORTE"
                            :label="FIELDS.MD_UNIGOE_ORTE"
                        ></category-filter>
                    </li>
                    <li
                        v-if="
                            $store.getters[
                                'cupboardsGameGenerator/hasCategoryEnoughRelatedItems'
                            ](FIELDS.MD_UNIGOE_PERSONEN)
                        "
                    >
                        <category-filter
                            :category="FIELDS.MD_UNIGOE_PERSONEN"
                            :label="FIELDS.MD_UNIGOE_PERSONEN"
                        ></category-filter>
                    </li>
                </ul>
                <div class="instruction">
                    {{ $t('cupboards_generator_instruction_categories') }}
                </div>
            </div>
            <div
                v-if="$store.state.cupboardsGameGenerator.selectedCategory"
                class="right"
            >
                <visualisation class="visualisation"></visualisation>
                <related-items class="related-items"></related-items>
            </div>
        </div>

        <cupboards-game-footer
            class="footer"
            :custom-close="true"
            @close="onCloseButtonClicked"
        ></cupboards-game-footer>
    </div>
</template>
<script>
import { STEPS } from '../../../../store/stations/3.001_cupboards/gameGenerator'
import { FIELDS } from '../../../../mixins/api'
import ItemInfo from '../../../global/ItemInfo.vue'
import RelatedItems from './RelatedItems.vue'
import Visualisation from './Visualisation.vue'
import Filters from './Filters.vue'
import CupboardsGameHeader from '../CupboardsGameHeader.vue'
import CupboardsGameFooter from '../CupboardsGameFooter.vue'
import { GAMES } from '../../../../store/stations/3.001_cupboards/cupboards'
import Texts from './Texts.vue'
import Filter from './Filter.vue'
import Radio from './Radio.vue'
import BoxWrapper from '../../../global/BoxWrapper.vue'

export default {
    name: 'CupboardsGameGenerator',
    components: {
        RelatedItems,
        Visualisation,
        Filters,
        CupboardsGameHeader,
        CupboardsGameFooter,
        Texts,
        ItemInfo,
        CategoryFilter: Filter,
        Radio,
        BoxWrapper,
    },
    data() {
        return {
            STEPS,
            FIELDS,
            GAMES,
        }
    },
    computed: {
        areRelatedItemsShown() {
            return (
                this.$store.getters['cupboardsGameGenerator/isStep'](
                    STEPS.SELECT,
                ) &&
                this.$store.getters['cupboardsGameGenerator/getRelatedItems']
                    .length > 0
            )
        },
    },
    methods: {
        onTextClicked() {
            this.$store.dispatch('cupboardsGameGenerator/setNextStep')
        },
        confirmSelection() {
            this.$store.dispatch('cupboardsGameGenerator/confirmSelection')
        },
        setFeaturedItem(item) {
            this.$store.dispatch('cupboardsGameGenerator/setFeaturedItem', item)
        },
        setCategoryAndProp(category, prop) {
            this.$store.dispatch(
                'cupboardsGameGenerator/setSelectedCategoryAndProp',
                { category, prop },
            )
        },
        onCloseButtonClicked() {
            console.log('close button, todo: show popup')
            this.$store.dispatch(
                'cupboardsGameGenerator/setStep',
                STEPS.CONCLUSION,
            )
        },
        onPopupClosed() {
            this.$store.dispatch('cupboards/setGame', GAMES.NONE)
        },
    },
}
</script>
<style lang="scss" scoped>
.gameGenerator {
    display: flex;
    flex-direction: column;
    .popup {
        position: absolute;
        width: 40%;
        height: 40%;
        transform: translateX(-50%) translateY(-50%);
        left: 50%;
        top: 50%;
        .popup-content {
            padding: 16px;
            font-size: 24px;
        }
        z-index: 1000;
    }

    .content {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        padding-left: 50px;
        padding-right: 50px;
        .left {
            width: 50%;
            height: 100%;
            .header {
                display: flex;

                .thumbnail {
                    max-height: 300px;
                    margin-bottom: 100px;
                }
                .title {
                    margin-left: 50px;
                    font-size: 32px;
                }
            }
            .text-categories {
                font-size: 28px;
                margin-bottom: 12px;
            }
            .filters {
                font-size: 24px;
                padding-left: 0;
                list-style-type: none;
                max-height: 400px;
                overflow-y: auto;
            }
        }
        .right {
            width: 50%;
            height: 100%;
            padding-left: 150px;
            .related-items {
                height: 320px;
                margin-top: 28px;
            }
        }
    }
}
</style>
