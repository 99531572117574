<template>
    <div id="cupboards" class="h-100 w-100 overflow-hidden">
        <cupboards-idle v-if="!$store.getters.isAUserLoggedIn"></cupboards-idle>
        <cupboards-game v-else />
    </div>
</template>

<script>
import CupboardsIdle from './CupboardsIdle.vue'
import CupboardsGame from './CupboardsGame.vue'

export default {
    name: 'CupboardsMain',
    components: { CupboardsIdle, CupboardsGame },
    data() {
        return {}
    },
    mounted() {
        this.$store.dispatch('cupboards/init')
        this.$store.dispatch('cupboardsGameTaxonomy/getItems')
        this.$store.dispatch('cupboardsGameHaystack/init')
        this.$store.dispatch('cupboards/getCollectionNames')
    },
}
</script>

<style lang="scss" scoped></style>
