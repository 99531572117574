import { render, staticRenderFns } from "./CupboardsGameHaystack.vue?vue&type=template&id=ca27cba0&scoped=true"
import script from "./CupboardsGameHaystack.vue?vue&type=script&lang=js"
export * from "./CupboardsGameHaystack.vue?vue&type=script&lang=js"
import style0 from "./CupboardsGameHaystack.vue?vue&type=style&index=0&id=ca27cba0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca27cba0",
  null
  
)

export default component.exports