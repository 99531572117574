<template>
    <div class="texts">
        <div
            v-if="
                $store.getters['cupboardsGameHaystack/isStep'](
                    STEPS.INTRODUCTION,
                )
            "
        >
            {{ $t('haystack_instruction') }}
        </div>
        <div
            v-if="
                $store.getters['cupboardsGameHaystack/isStep'](STEPS.FIND) &&
                    $store.getters['cupboardsGameHaystack/areFiltersDirty']
            "
        >
            <span
                v-if="
                    $store.getters[
                        'cupboardsGameHaystack/isSelectedItemInFilteredItems'
                    ]
                "
            >
                {{ $t('haystack_included_hint_positive') }}
            </span>
            <span v-else>
                {{ $t('haystack_included_hint_negative') }}
            </span>
        </div>
        <div
            v-if="$store.getters['cupboardsGameHaystack/isStep'](STEPS.RESULT)"
        >
            <div
                v-if="
                    $store.state.cupboardsGameHaystack.selectResult ===
                        SELECTRESULTS.MISTAKE
                "
                class="message mistake"
            >
                {{ $t('haystack_mistake') }}
            </div>

            <div
                v-if="
                    $store.state.cupboardsGameHaystack.selectResult ===
                        SELECTRESULTS.SUCCESS
                "
                class="message success"
            >
                {{ $t('haystack_success') }}
            </div>
        </div>
        <div
            v-if="
                $store.getters['cupboardsGameHaystack/isStep'](STEPS.GAMEOVER)
            "
        >
            <span
                v-if="
                    $store.state.cupboardsGameHaystack.selectResult ===
                        SELECTRESULTS.SUCCESS
                "
            >
                {{ $t('haystack_gameover_positive') }}
            </span>
            <span
                v-if="
                    $store.state.cupboardsGameHaystack.selectResult ===
                        SELECTRESULTS.MISTAKE
                "
            >
                {{ $t('haystack_gameover_negative') }}
            </span>
        </div>
    </div>
</template>

<script>
import {
    SELECTRESULTS,
    STEPS,
} from '../../../../store/stations/3.001_cupboards/gameHaystack'

export default {
    name: 'CupboardsGameHaystackTexts',
    data() {
        return { STEPS, SELECTRESULTS }
    },
    computed: {},
    async mounted() {},
    beforeDestroy: function() {},
    methods: {},
}
</script>

<style scoped lang="scss">
.texts {
    color: var(--secondary);
}
</style>
